import React from "react";
import { styled } from "styled-components";
import ContactForm from "../components/contactForm";
import { colors } from "../colors/colors";

export const Title = styled.h1`
	color: ${colors.navy};
	text-align: center;

	/* heading1 */
	font-family: Inter;
	font-size: 32px;
	font-style: normal;
	font-weight: 300;
	line-height: 40px; /* 125% */
	margin-top: 120px;
`;

const ContactText = styled.p`
	color: ${colors.navy};
	font-family: Inter;
	font-size: 20px;
	font-style: normal;
	font-weight: 300;
	line-height: 36px; /* 180% */
	margin: 0;
	text-align: justify;
`;

const CenteredContainer = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	max-width: 800px;
	padding-left: 50px;
	padding-right: 50px;
`;

const EmptyBox = styled.div`
	height: calc(100vh - 961px);
`;


const ContactUs = () => {
  return (
    <div>
      <Title>Contact Us</Title>
	  <center><CenteredContainer><ContactText>Interested in Cubionic Portal?  Do you want to share a great idea?  Tell us all about it!</ContactText></CenteredContainer>
		<ContactForm/></center>
		<EmptyBox/>
    </div>
  );
};

export default ContactUs;
