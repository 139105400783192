export const colors = {
  red: '#FF6060',
  yellow: '#FCFF60',
  green: '#63FF60',
  inactive: '#D9D9D9',
  darkWhite: '#F9F9FC',
  whiteGrey: '#F5F5F5',
  lightGrey: '#EEEEF2',
  grey: '#CCCCCC',
  darkGrey: '#808192',
  navy: '#212F3D',
  tableGrey: '#4B4B56',
  black: '#000000',
  labelGrey: '#999999',
  linkColor: '#00A3FF',
  deepPurple: '#484964'
};