import { useEffect } from 'react';

const HubSpotTracking = () => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = '//js-na1.hs-scripts.com/43581549.js'
    script.id = "hs-script-loader"
    document.body.appendChild(script)
  }, [])
  return null;
}

export default HubSpotTracking;