import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import cubiLogo from "../images/cubiLogo.png";
import chevronDownImage from "../images/chevronDown.png";
import { useNavigate } from 'react-router-dom';
import { colors } from "../colors/colors";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from "@fortawesome/free-solid-svg-icons";
import './NavBar.css'; 

const NavBar = () => {
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
    };
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 630);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleProductHover = () => {
    setIsDropdownOpen(true);
  };

  const handleProductLeave = () => {
    setIsDropdownOpen(false);
  };

  const handleAboutUsClick = () => {
    navigate('/about');
    scrollToTop();
  };

  const handleContactUsClick = () => {
    navigate('/contactUs');
    scrollToTop();
  };

  const handleDropdownItemClick = () => {
    setIsDropdownOpen(false);
  };

  const handleMobileMenuClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const NavigationBar = styled.div`
    display: flex;
    width: 100%;
    height: 80px;
    align-items: center;
    gap: 44px;
    background-color: ${colors.navy};
    position: absolute;
    top: 0;
    left: 0;
  `;

  const LogoImage = styled.img`
    height: 60px;
    width: 80px;
    padding: 10px 20px; 
  `;

  const PageLink = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid ${colors.linkColor}; 
    cursor: pointer;
    color: white;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 40px;
    z-index: 2;
    &:hover {
      border-bottom: 1px solid white; 
    }
  `;

  const ChevronDownIcon = styled.img`
    height: 20px;
    width: 20px;
  `;

  const DropdownContainer = styled.div`
    position: absolute;
    top: 75%;
    left: 150px;
    display: ${isDropdownOpen ? 'flex' : 'none'};
    flex-direction: column;
    background-color: ${colors.navy};
    z-index: 1;
    padding-top:20px;
    color: white;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 40px;
  `;

  const DropdownItem = styled.div`
    padding: 8px 20px;
    color: white;
    cursor: pointer;
    font-family: Inter;
    font-size: 18px;
    &:hover {
      background-color: ${colors.linkColor};
    }
  `;

  const PageButton = styled.button`
    display: flex;
    height: 40px;
    padding: 8px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px; 
    border-radius: 3px;
    border: 1px solid ${colors.linkColor};
    background: ${colors.linkColor};
    cursor: pointer;
    color: white;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 40px;
    &:hover {
      color: ${colors.linkColor};
      background-color: white;
    }
  `;

  return (
    <div>
      {!isMobile && ( // Desktop version
        <NavigationBar>
          <LogoImage src={cubiLogo} alt="Cubi Logo" />
          <PageLink
            onClick={() => {navigate('/'); scrollToTop();}}
            onMouseEnter={handleProductHover}
            onMouseLeave={handleProductLeave}
          >
            Product <ChevronDownIcon src={chevronDownImage} alt="Chevron Down" />
          </PageLink>
          {isDropdownOpen && (
            <DropdownContainer
              onMouseEnter={handleProductHover}
              onMouseLeave={handleProductLeave}
            >
              <DropdownItem onClick={() => { navigate('/'); handleDropdownItemClick(); scrollToTop(); }}>
                Cubionic Portal
              </DropdownItem>
              <DropdownItem onClick={() => { navigate('/customDevelopment'); handleDropdownItemClick(); scrollToTop(); }}>
                Custom Development
              </DropdownItem>
            </DropdownContainer>
          )}
          <PageLink onClick={handleAboutUsClick}>
            About Us
          </PageLink>
          <PageButton onClick={handleContactUsClick}>Contact Us</PageButton>
        </NavigationBar>
      )}

      {isMobile && (
        <NavigationBar>
          <LogoImage src={cubiLogo} alt="Cubi Logo" />
          <FontAwesomeIcon
            icon={faBars}
            style={{
              height: '35px',
              color: '#ffffff',
              cursor: 'pointer',
              position: 'absolute',
              right: '35px'
            }}
            onClick={handleMobileMenuClick} // Toggle the dropdown when the icon is clicked
            className="icon-hover" 
          />

          {/* Mobile Dropdown Menu */}
          {isDropdownOpen && (
            <DropdownContainer>
              <DropdownItem onClick={() => { navigate('/'); handleDropdownItemClick(); scrollToTop(); }}>
                Cubionic Portal
              </DropdownItem>
              <DropdownItem onClick={() => { navigate('/customDevelopment'); handleDropdownItemClick(); scrollToTop(); }}>
                Custom Development
              </DropdownItem>
              <DropdownItem onClick={() => { handleAboutUsClick(); handleDropdownItemClick(); }}>
                About Us
              </DropdownItem>
              <DropdownItem onClick={() => { handleContactUsClick(); handleDropdownItemClick(); }}>
                Contact Us
              </DropdownItem>
            </DropdownContainer>
          )}
        </NavigationBar>
      )}
    </div>
  );
};

export default NavBar;
