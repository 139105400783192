import React from "react";
import { Title } from "./ContactUs";
import { styled } from "styled-components";
import Highlight from "../components/highlight";

export const TextDiv = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 20px;
	max-width: 800px;
	margin: 0px 50px 0px 50px;
`;

export const TextBlock = styled.p`
	color: #000;
	font-family: Inter;
	font-size: 20px;
	font-style: normal;
	font-weight: 300;
	line-height: 36px; /* 180% */
	text-align: justify;
`;

export const EmptyBlock = styled.div`
	height: calc(100vh - 929px);
`;

const CustomDevelopment = () => {

	const highlightText = "Tell us what you need";

	return (
		<div>
			<Title>Custom Development for Cubionic Portal</Title>
			<center><TextDiv><TextBlock>We grow with you.  That’s why we offer custom development with Cubionic Portal to ensure that your business and operations gets the features it needs to ensure your success.<br/><br/>We know not everyone has the same needs in their management systems and anywhere it reads “one size fits all” should be replaced with “one size fits some”.
Let us know how we can help you get that extra edge, be better, and go farther.<br/><br/>Cubionic has decades of combined experience building management systems for different industries and sectors.  Together, we can build something truly great to ensure your continued success.</TextBlock></TextDiv></center>
			<center><Highlight highlightText={highlightText}/></center>
			<EmptyBlock/>
		</div>
	);
};

export default CustomDevelopment;
