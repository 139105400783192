import React, { useEffect, useRef, useState } from "react";
import { CentredBox, ContentBox } from "./customBlock1";
import { styled } from "styled-components";
import Line from "../images/Line.png";

const CustomBlock2 = () => {
    const text1 = "The Cubionic Portal helps your Service Team be their best by giving them full insight into every device and the ability to manage devices remotely.";
    const text2 = "Reduce costly onsite visits by remotely updating your device software.  Send remote commands to your devices such as reboots and self maintenance without sending a technician to the field.";

    const contentBoxRef = useRef(null);

    const [contentBoxWidth, setContentBoxWidth] = useState(0);

    useEffect(() => {
        const handleResize = () => {
            if (contentBoxRef.current) {
                const width = contentBoxRef.current.clientWidth;
                setContentBoxWidth(width);
            }
        };

        // Initial setup
        handleResize();

        // Attach event listener for window resize
        window.addEventListener("resize", handleResize);

        // Cleanup the event listener when component is unmounted
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <CentredBox style={{ flexDirection: 'column' }}>
            <ContentBox ref={contentBoxRef}>{text1}</ContentBox>
            <BracketLine src={Line} width={contentBoxWidth} />
            <ContentBox>{text2}</ContentBox>
        </CentredBox>
    )
}

const BracketLine = styled.img`
    width: ${props => props.width}px;
    margin: 20px 0px 20px 0px;
`;

export default CustomBlock2;
