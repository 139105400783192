import React from "react";
import { Title } from "./ContactUs";
import ContentBlock from "../components/contentBlock";
import Image1 from "../images/Image1.png";
import Image2 from "../images/Image2.png";
import Image3 from "../images/Image3.png";
import Image4 from "../images/Image4.png";
import Image5 from "../images/Image5.png";
import Image6 from "../images/Image6.png";
import Image7 from "../images/Image7.png";
import Image8 from "../images/Image8.png";
import Image9 from "../images/Image9.png";
import Image10 from "../images/Image10.png";
import Image11 from "../images/Image11.png";
import Highlight from "../components/highlight";
import ContentBlockPurple from "./contentBlockPurple";
import { styled } from "styled-components";
import InvertedBlock from "../components/invertedBlock";
import CustomBlock1 from "../components/customBlock1";
import CustomBlock2 from "../components/customBlock2";

const Home = () => {

const title1 = "Designed to manage smart device hardware";
const content11 = "Cubionic Portal is designed to manage your devices whether they’re kiosks, monitoring equipment, vending machines, and more.";
const content12 = "We work with hardware manufacturers to capture all necessary data and give you the best insights.";

const title2 = "Designed for Your Devices";
const content21 = "Our centralized management system puts your devices front and center so you can get valuable insights for usage, maintenance, monitoring, reporting and more with a single login.";

const title3 = "We Have You Covered";
const content31 = "Your focus on amazing devices shouldn’t be distracted by software development.";
const content32 = "You can stay focused on your devices and your business, we’ll worry about the portal.";

const title4 = "Established Framework with Common Interfaces and Ready to Go";
const content41 = "Cubionic Portal has a simple REST based API, along with SDKs in common languages to make integration with smart devices straightforward, regardless of the technology stack used.";
const content42 = "We have common reports already defined and can build additional reports based on your specific requirements.";

const title5 = "Complete Usage History";
const content51 = "Run reports and see every event and transaction for all of your devices.";

const title6 = "Increase Uptime";
const content61 = "Using trend data, you can easily identify and address devices impacting your business from recurring failures.";

const title7 = "Device Health";
const content71 = "Get fast insight into your devices with clear status lights, sorted by urgency so you don’t have to dig for data.";

const title8 = "Detailed Events";
const content81 = "Click on any event to get full details.";
const content82 = "We don’t hide your data from you.";

const title9 = "Real-time alerts";
const content91 = "Cubionic Portal can send you alerts as soon as they happen to help you manage your devices.";
const content92 = "Alerts can be configured to trigger emails, SMS messages, or even phone calls.";

const title10 = "Track inventories";
const content101 = "Cubionic Portal gives you insight into your device’s inventory and tells you when things need to be restocked or emptied.";
const content102 = "We have a flexible inventory pattern that allows you to track your inventory whether it’s consumables or remaining capacity.";

const title11 = "Customized features";
const content111 = "Not everyone has the same needs in their management system and “one size fits all” should be replaced with “one size fits some”.";
const content112 = "We've built various management systems over the years and know that each one has specific needs and customizations unique to their application.";

const highlightText1 = "Let us know how we can help you";
const highlightText2 = "Interested?";
const highlightText3 = "Need something special?";

const MarginBlock = styled.div`
	height: 25px;
`;

	return (
		<div>
			<Title>Cubionic Portal Device Management System</Title>
				<ContentBlock title={title1} content1={content11} content2={content12} imageSrc={Image1} />
				<center><Highlight highlightText = {highlightText1}/></center>
				<InvertedBlock title={title2} content1={content21} imageSrc={Image2} />
				<ContentBlock title={title3} content1={content31} content2={content32} imageSrc={Image3} />
				<ContentBlockPurple title={title4} content1={content41} content2={content42} imageSrc={Image4} />
				<MarginBlock/>
				<CustomBlock1/>
				<ContentBlock title={title5} content1={content51}  imageSrc={Image5} />
				<ContentBlock title={title6} content1={content61}  imageSrc={Image6} />
				<center><Highlight highlightText = {highlightText2}/></center>
				<CustomBlock2/>
				<InvertedBlock title={title7} content1={content71} imageSrc={Image7} />
				<InvertedBlock title={title8} content1={content81} content2={content82} imageSrc={Image8} />
				<InvertedBlock title={title9} content1={content91} content2={content92} imageSrc={Image9} />
				<InvertedBlock title={title10} content1={content101} content2={content102} imageSrc={Image10} />
				<center><Highlight highlightText = {highlightText2}/></center>
				<ContentBlockPurple 
					title={title11} 
					content1={content111} 
					content2={content112} 
					imageSrc={Image11}
				/>
				<MarginBlock/>
				<center><Highlight highlightText = {highlightText3}/></center>
		</div>
	);
};

export default Home;
