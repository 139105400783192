import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { colors } from "../colors/colors";
import { useNavigate } from 'react-router-dom';

const HighlightContainer = styled.div`
  display: flex;
  max-width: 825px;
  height: 100px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 5px;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 30px;
`;

const HighlightContainerMobile = styled.div`
  display: flex;
  flex-direction: column;
  height: 140px;
  margin: 10px 30px 20px 30px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 5px;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;

const HighlightText = styled.p`
  color: #00A3FF;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

const HighlightTextMobile = styled.p`
  color: #00A3FF;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 0px 0px;
`;

export const PageButton = styled.button`
  display: flex;
  height: 40px;
  padding: 8px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px; 
  border-radius: 3px;
  border: 1px solid ${colors.linkColor};
  background: ${colors.linkColor};
  cursor: pointer;
  color: white;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 40px;
  &:hover {
    color: ${colors.linkColor};
    background-color: white;
  }
  &:active {
    transform: scale(1.1);
  }
`;

const Highlight = ({highlightText}) => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 630);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleContactUsClick = () => {
    navigate('/contactUs');
    scrollToTop();
  };

  return (
    <div>
      {!isMobile && (
        // Desktop version
        <HighlightContainer>
          <HighlightText>{highlightText}</HighlightText>
          <PageButton onClick={handleContactUsClick}>Contact Us</PageButton>
        </HighlightContainer>
      )}

      {isMobile && (
        // Mobile version
        <HighlightContainerMobile>
          <HighlightTextMobile>Tell us what you need</HighlightTextMobile>
          <PageButton onClick={handleContactUsClick}>Contact Us</PageButton>
          </HighlightContainerMobile>
      )}
    </div>
  );
};

export default Highlight;
