import React, { useRef, useEffect, useState } from "react";
import { styled } from "styled-components";
import { colors } from "../colors/colors";
import bracketLeft from "../images/bracketLeft.png";
import bracketRight from "../images/bracketRight.png";

const CustomBlock1 = () => {
  const text = "As your device count grows, the time and effort required to monitor individual devices becomes overwhelming. The Cubionic Portal solves this problem and allows you to keep an eye on your devices and manage them at scale. Centralized monitoring and alerting allows for a streamlined management process.";

  // Create a ref to store the height of the ContentBox
  const contentBoxRef = useRef(null);

  // State to store the height of the ContentBox
  const [contentBoxHeight, setContentBoxHeight] = useState(0);

  // useEffect hook to measure the height of ContentBox after rendering
  useEffect(() => {
    if (contentBoxRef.current) {
      const height = contentBoxRef.current.clientHeight;
      // Store the height in state
      setContentBoxHeight(height);
    }
  }, []);

  return (
    <CentredBox>
      {/* Use the dynamic height in the BracketImage */}
      <BracketImage src={bracketLeft} height={contentBoxHeight} />
      {/* Use the ref to measure the ContentBox height */}
      <ContentBox ref={contentBoxRef}>{text}</ContentBox>
      <BracketImage src={bracketRight} height={contentBoxHeight} />
    </CentredBox>
  );
};

export const CentredBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center; /* Align items vertically center */
  padding: 40px;
  background: ${colors.deepPurple};
`;

// Use props to set the height of the BracketImage dynamically
const BracketImage = styled.img`
  height: ${props => props.height}px;
`;

export const ContentBox = styled.p`
  max-width: 800px;
  color: white;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 30px; /* 180% */
  margin: 0;
  text-align: justify;
  padding: 0px 25px 0px 25px;
`;

export default CustomBlock1;
