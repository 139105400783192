import React from "react";
import { Title } from "./ContactUs";
import { TextBlock, TextDiv } from "./CustomDevelopment";
import Highlight from "../components/highlight";
import { styled } from "styled-components";

export const TextList = styled.li`
	color: #000;
	font-family: Inter;
	font-size: 20px;
	font-style: normal;
	font-weight: 300;
	line-height: 36px; /* 180% */
	text-align: justify;
	max-width: 800px;
`;

const About = () => {

	const highlightText = "Tell us what you need";

	return (
		<div>
			<Title>Who we are</Title>
			<center><TextDiv>
				<TextBlock>We are technologists experienced in building software products of all shapes and sizes across various industries. We know how challenging it can be to add capable engineers to your development team, because we've led organizations like yours before we became Cubionic.<br/><br/> When your workload starts exceeding your capacity, you need a flexible partner who can address your needs as they evolve. You may find:</TextBlock>
				<div style={{ marginLeft: '15px' }}>
				<TextList>You have services that you must deliver, but they are distracting more critical efforts central to your team’s core competencies</TextList>
				<TextList>You have been unable to hire for specific skill sets missing from your current team</TextList>
				<TextList>You are currently unable to recruit employees, but your team is short-handed</TextList>
				</div>
				<TextBlock>We pride ourselves in our ability to seamlessly integrate into your organization’s operations. Our services don’t come with a list of code languages or tools required, because our team’s skills are truly full-stack. Our knack for communication and ability to ramp up quickly makes us a stellar addition to your team.<br/><br/>We are located in San Diego and meet weekly with our local active partners face-to-face. We genuinely enjoy being a part of your team, and we think nothing beats being in the same room when planning and reviewing our work. Not local, or can’t meet face-to-face? No problem, we can sync up according to your preferences.<br/></TextBlock>
				</TextDiv></center>

			<center><Highlight highlightText = {highlightText}/></center>

			
		</div>
	);
};

export default About;