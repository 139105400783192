import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../colors/colors";

function Footer() {
    const navigate = useNavigate();
    let currentYear = new Date().getFullYear();

    const scrollToTop = () => {
    window.scrollTo(0, 0);
    };

  return (
    <>
       <FooterBar>
        <Content>Cubionic, Inc.</Content>
        <Link onClick={() => {navigate('/'); scrollToTop();}}>Cubionic Portal</Link>
        <Link onClick={() => {navigate('/customDevelopment'); scrollToTop();}}>Custom Development</Link>
        <Link onClick={() => {navigate('/about'); scrollToTop();}}>About Us</Link>
        <Link onClick={() => {navigate('/contactUs'); scrollToTop();}}>Contact Us</Link>
        <Content>Copyright Cubionic ©{currentYear}</Content>
        <Content>Questions?  <Link href="mailto:contact@cubionic.com?subject=Question">contact@cubionic.com</Link></Content>
        </FooterBar>
    </>
  )
}

const FooterBar = styled.div`
  position: relative;
  z-index: 3;
  height: 188px;
  background: ${colors.navy};
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;
`;

const Content = styled.p`
  color: white;
  z-index: 1;
  margin-bottom: 0;
  font-family: 'Inter',sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 14px;
`;

const Link = styled.a`
  color: ${colors.linkColor};
  z-index: 1;
  font-family: 'Inter',sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 14px;
  margin-bottom: 0px;
  cursor: pointer;
`;

export default Footer;