import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages';
import About from './pages/AboutUs';
import CustomDevelopment from './pages/CustomDevelopment';
import ContactUs from './pages/ContactUs';
import NavBar from './components/NavBar';
import Footer from './components/footer';
import HubSpotTracking from './components/hubspotTracking'
import './index.css';

function App() {



  return (
    <Router>
      <NavBar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/customDevelopment' element={<CustomDevelopment />} />
        <Route path='/contactUs' element={<ContactUs />} />
      </Routes>
      <Footer/>
      <HubSpotTracking/>
    </Router>

  );
}

export default App;