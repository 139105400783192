import React, { useEffect, useState } from "react";
import { styled } from "styled-components";

const GridBox = styled.div`
    display: flex;
    max-width: 800px;
    align-items: center;
`;

const GridMobileBox = styled.div`
    display: flex;
    max-width: 800px;
    align-items: center;
    flex-direction: column;
`;

const ColumnBox = styled.div`
    display: flex;
    width: 400px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    flex-shrink: 0;
    align-self: stretch;
`;

const ColumnBoxMobile = styled.div`
    display: flex;
    max-width: 320px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    flex-shrink: 0;
    align-self: stretch;
`;

const TitleBox = styled.p`
    max-width: 380px;
    color: #000; //that one can be changes
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 300;
    line-height: 40px;
    margin: 0;
    text-align: inherit;
`;

const TitleMobileBox = styled.p`
    max-width: 380px;
    color: #000; //that one can be changes
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    margin: 0;
    text-align: center;
`;

const ContentBox = styled.p`
    color: #000; //that one can be changes
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px; /* 180% */
    margin: 0;
    text-align: justify;
`;

const ImageBox = styled.img`
    width: 400px; 
    height: 400px;
    margin-left: 25px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
`;

const ImageMobileBox = styled.img`
    width: 300px; 
    height: 300px;
    margin-top: 25px;
`;

const ContentBlock = ({ title, content1, content2, imageSrc }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 630);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const CentredBox = styled.div`
    display: flex;
    justify-content: center;
    padding: 40px;
    background: none; //that one can be changes
`;


  return (
    <div>
      {!isMobile && (
        // Desktop version
        <CentredBox>
        <GridBox>
            <ColumnBox>
                {/* All text boxes will be here */}
                <TitleBox>{title}</TitleBox>
                <ContentBox>{content1}</ContentBox>
                <ContentBox>{content2}</ContentBox>
            </ColumnBox>

            <div>
                {/* Image will be here */}
                <ImageBox src={imageSrc} alt="Image 1" />
            </div>
        </GridBox>
        </CentredBox>
      )}

      {isMobile && (
        // Mobile version
        <CentredBox>
            <GridMobileBox>
                <ColumnBoxMobile>
                    {/* All text boxes will be here */}
                    <TitleMobileBox>{title}</TitleMobileBox>
                    <ContentBox>{content1}</ContentBox>
                    <ContentBox>{content2}</ContentBox>
                </ColumnBoxMobile>

                <div>
                {/* Image will be here */}
                <ImageMobileBox src={imageSrc} alt="Image 1" />
                </div>
            </GridMobileBox>
        </CentredBox>
      )}
    </div>
  );
};

export default ContentBlock;
