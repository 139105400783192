import React, { useEffect } from "react";
import { styled } from "styled-components";
import spinner from "../images/Blocks-1s-200px.gif";

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px;
  min-height: 520px;
  max-width: 800px;
  text-align: justify;
`;

const SpinnerImage = styled.img`
  margin: 0 auto;
  display: flex
`;

const ContactForm = (_props) => {

  useEffect(() => {
    const script = document.createElement('script')
    script.src = '//js.hsforms.net/forms/embed/v2.js'
    document.body.appendChild(script)

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          target: '#contactForm',
          region: "na1",
          portalId: "43581549",
          formId: "96ec1fa9-d7c7-4247-abfa-1cf544e26440"
        })
      }
    })
  }, [])

  return (
    <FormContainer>
      <div id="contactForm"><SpinnerImage src={spinner} /></div>
    </FormContainer>
  );
}

export default ContactForm
